.spinner {
  display: none;
  height: 30px;
  width: 30px;
  margin: 8px auto 9px auto;
  position: relative;
  -webkit-animation: rotation .8s infinite linear;
  -moz-animation: rotation .8s infinite linear;
  -o-animation: rotation .8s infinite linear;
  animation: rotation .8s infinite linear;
  border-right: 3px solid rgba($primary, 0.5);
  border-bottom: 3px solid $primary;
  border-left: 3px solid $primary;
  border-top: 3px solid $primary;
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}