@import "custom";
@import "bootstrap";
@import "spinner";

html,
body {
  overflow-x: hidden; /* prevent scroll on narrow devices */
}

body {
  padding-top: 84px; /* height of navbar */
}

@media (max-width: 767.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 84px; /* height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: $light;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.info-icon {
  img {
    width: 4.5rem;
    height: 4.5rem;
  }

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 7rem;
  margin-bottom: 1rem;
  border-radius: 50%;
}

.step-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 7rem;
  margin-bottom: 1rem;
  font-size: 6.5rem;
  color: #fff;
  border-radius: 50%;
  padding-bottom: .4rem;
}

.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  border-radius: 50%;
}

.bg-step1 {
  background-color: $gradient1;
}

.bg-step2 {
  background-color: $gradient2;
}

.bg-step3 {
  background-color: $gradient3;
}

.bg-primary-gradient {
  /* fallback for old browsers */
  background: $primary;
  @include gradient-x-three-colors($gradient1, $gradient2, 50%, $gradient3);

  &.btn-primary {
    color: #fff;
  }

  &.btn-primary:hover {
    @include gradient-x-three-colors(darken($gradient1, 5%), darken($gradient2, 5%), 50%, darken($gradient3, 5%));
  }

  &.btn-primary:active {
    @include gradient-x-three-colors(darken($gradient1, 10%), darken($gradient2, 10%), 50%, darken($gradient3, 10%));
  }
}

ol.terms-list, ol.terms-list ol {
  counter-reset: item;
}

.terms-list li {
  display: block;
  position: relative;
}

.terms-list li:before {
  content: counters(item, ".")".";
  counter-increment: item;
  position: absolute;
  margin-right: 100%;
  right: 10px; /* space between number and text */
}

.esign-list {
  list-style: upper-alpha;
}

.privacy-policy tbody > tr:last-child {
  th, td {
    border-bottom-width: 0;
  }
}
